<template>
  <v-layout class="row wrap px-4 pb-4">
    <v-flex class="xs12">
      <v-layout>
        <v-flex class="xs6">
          <div class="px-4 pt-4 font-weight-bold">
            {{ $t("audience_page.total_group_users_label") }}:
            {{ totalGroupUsers }}
          </div>
        </v-flex>
        <v-flex class="xs6">
          <v-text-field
            v-model="audienceSearchUserInput"
            :label="$t('audience_page.user_search_input_label')"
            append-icon="search"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
    </v-flex>
    <v-flex
      v-if="groupUsers.length > 0"
      class="xs12"
      :class="{ loading: isLoading }"
    >
      <v-layout v-for="user in groupUsers" :key="user.id" class="row wrap">
        <v-flex class="xs12">
          <v-layout
            class="row wrap full-width text-xs-left py-3 align-items-center"
          >
            <v-flex class="xs3 pl-4">
              <span>{{ user.first_name }} {{ user.last_name }}</span>
            </v-flex>
            <v-flex class="xs3">
              <span v-if="user.phone_code && user.phone"
                >+{{ user.phone_code }} {{ user.phone }}</span
              >
            </v-flex>
            <v-flex class="xs5">{{ user.email }}</v-flex>
            <v-flex class="xs1">
              <a
                :href="userEditLink(user.id)"
                target="_blank"
                class="sw-accent"
              >
                <font-awesome-icon icon="external-link-alt" />
              </a>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="xs12">
          <v-divider></v-divider>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex v-if="totalPages > 1" class="xs12 mt-4 text-xs-center">
      <v-pagination
        v-model="page"
        :length="totalPages"
        :total-visible="totalVisible"
        @input="listGroupUsers"
      ></v-pagination>
    </v-flex>
    <v-flex
      v-if="exportsButtonShow && groupUsers.length > 0"
      class="xs12 pt-4 text-right"
    >
      {{ $t("audience_page.export_label") }}
      <!-- PDF -->
      <v-tooltip top>
        <v-btn
          slot="activator"
          icon
          class="ma-0"
          @click="downloadAudience('pdf')"
        >
          <font-awesome-icon class="sw-accent" icon="file-pdf" />
        </v-btn>
        <span>{{ $t("download") }} PDF</span>
      </v-tooltip>
      <!-- HTML -->
      <v-tooltip top>
        <v-btn
          slot="activator"
          icon
          class="ma-0"
          @click="downloadAudience('html')"
        >
          <font-awesome-icon class="sw-accent" icon="file-code" />
        </v-btn>
        <span>{{ $t("download") }} HTML</span>
      </v-tooltip>
      <!-- XLSX -->
      <v-tooltip top>
        <v-btn
          slot="activator"
          icon
          class="ma-0"
          @click="downloadAudience('xlsx')"
        >
          <font-awesome-icon class="sw-accent" icon="file-excel" />
        </v-btn>
        <span>{{ $t("download") }} XLS</span>
      </v-tooltip>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import fileDownload from "js-file-download";

export default {
  data: () => ({
    isLoading: false,
    audienceSearchUserInput: "",
    groupUsers: [],
    totalGroupUsers: null,
    timeout: null,
    page: 1,
    perPage: 15,
    totalPages: null,
    totalVisible: 5,
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    audienceId() {
      return this.$route.params.audience_id;
    },
    userEditLink() {
      return (userId) => `/#/groups/${this.groupId}/members/${userId}/edit`;
    },
    exportsButtonShow() {
      return this.$route.name !== "audiences_create";
    },
    audienceFilters: {
      get() {
        return this.$store.getters.audienceFilters;
      },
      set(v) {
        this.$store.commit("SET_AUDIENCE_FILTERS", v);
      },
    },
  },
  watch: {
    audienceFilters: {
      immediate: true,
      deep: true,
      handler() {
        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          this.page = 1;
          this.listGroupUsers();
        }, 500);
      },
    },
    audienceSearchUserInput(newVal, oldVal) {
      if (JSON.stringify(newVal) === JSON.stringify(oldVal)) {
        return;
      }

      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.page = 1;
        this.listGroupUsers();
      }, 500);
    },
  },
  methods: {
    async listGroupUsers() {
      try {
        const params = [
          this.groupId,
          {
            filters: this.audienceFilters,
            ...(this.audienceSearchUserInput && {
              search: this.audienceSearchUserInput,
            }),
            page: this.page,
            per_page: this.perPage,
          },
        ];

        this.isLoading = true;

        const response = await this.$api.groupUsers.list(...params);

        this.isLoading = false;

        this.groupUsers = response.data.data;
        this.totalGroupUsers = response.data.pagination.total;
        this.totalPages = response.data.pagination.total_pages;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async downloadAudience(format = "html") {
      try {
        const search = this.audienceSearchUserInput
          ? "&search=" + encodeURIComponent(this.audienceSearchUserInput)
          : "";
        const filename = `audience_list.${format}`;

        const response = await axios({
          url: `${process.env.VUE_APP_API_URL}groups/${this.groupId}/audiences/${this.audienceId}/export?format=${format}${search}`,
          method: "GET",
          responseType: "blob",
        });

        fileDownload(response.data, filename);
      } catch (error) {
        if (error) {
          this.$store.dispatch("addErrorNotification", {
            message:
              error & error.response &&
              error.response.data &&
              error.response.data.error
                ? error.response.data.error.message
                : this.$t("internalServerError"),
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  opacity: 0.4;
  transition: all 0.2s;
  pointer-events: none;
}
</style>
