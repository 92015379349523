<template>
  <v-container class="general">
    <page-title :component="'AudienceListPage'">
      <template v-slot:actions>
        <VideoTutorial
          :component="'AudienceListPage'"
          :title="$t('audiences-tutorial-title')"
        />
      </template>
    </page-title>

    <v-layout
      class="mb-4"
      :class="{ loading: isLoading }"
    >
      <v-flex class="xs6">
        <v-layout class="row wrap">
          <v-flex class="xs12">
            <TranslatableTooltip
              :content="$t('audience_page.name_label_tooltip')"
            >
              <div class="sw-h5">{{ $t('audience_page.name_label') }}</div>
            </TranslatableTooltip>
          </v-flex>
          <v-flex class="xs12 mb-5">
            <v-text-field
              v-model="audience.name"
              :error-messages="errors.collect('name')"
              v-validate.disable="'required|min:3'"
              data-vv-name="name"
              :data-vv-as="$t('audience_page.name_data_vv_as')"
              :placeholder="$t('audience_page.name_placeholder')"
            ></v-text-field>
          </v-flex>
          <v-flex class="xs12">
            <TranslatableTooltip
              :content="$t('audience_page.description_label_tooltip')"
            >
              <div class="sw-h5">
                {{ $t('audience_page.description_label') }}
              </div>
            </TranslatableTooltip>
          </v-flex>
          <v-flex class="xs12 mb-5">
            <v-text-field
              v-model="audience.description"
              hide-details
              :placeholder="$t('audience_page.description_placeholder')"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <transition
      name="fade"
      mode="out-in"
    >
      <v-layout
        v-show="isLoaded"
        v-if="!audienceFilterAll"
        class="row wrap mb-4"
      >
        <v-flex class="xs12 mb-4">
          <div class="sw-h5">{{ $t('audience_page.filters_label') }}</div>
        </v-flex>
        <v-flex
          v-if="audienceFiltersTransformed().length === 0"
          class="xs12 mb-5"
        >
          <div class="sw-caption grey--text">
            {{ $t('audience_page.filters_empty_text') }}
          </div>
        </v-flex>
        <v-flex
          v-if="audienceFiltersTransformed().length > 0"
          class="xs12 mb-5"
        >
          <v-layout class="row wrap mx-n3">
            <v-flex
              v-for="(filter, i) in audienceFiltersTransformed()"
              :key="`item-${i}`"
              class="xs4 py-2 px-3"
            >
              <v-card class="fill-height">
                <v-btn
                  fab
                  class="ma-0 sw-accent-bg sw-on-accent small-fab"
                  @click="removeFilter(i)"
                >
                  <v-icon>close</v-icon>
                </v-btn>
                <v-card-text>
                  <div class="mb-2">
                    <div
                      v-if="filter.group"
                      class="font-weight-bold"
                    >
                      {{ filter.group }}
                    </div>
                    <div v-if="filter.question">
                      {{ filter.question }}
                    </div>
                  </div>
                  <div class="mx-n1">
                    <span
                      v-for="(option, optionIndex) in filter.options"
                      :key="`option-${optionIndex}`"
                    >
                      <v-chip>
                        {{ option }}
                      </v-chip>
                    </span>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </transition>

    <v-card
      v-if="isLoaded"
      class="mb-4 border-radius"
      :class="{ loading: isLoading }"
    >
      <v-layout class="row wrap">
        <v-flex class="xs5 pa-4 grey lighten-4">
          <AudienceFilters />
        </v-flex>
        <v-flex class="xs7 pb-4">
          <AudienceUsers />
        </v-flex>
      </v-layout>
    </v-card>

    <v-layout>
      <v-flex class="xs12">
        <v-btn
          v-if="audienceId"
          round
          large
          class="ml-0 mr-3 white sw-accent text-none"
          :disabled="!isEditable"
          :loading="isLoading"
          @click="deleteAudience()"
          >{{ $t('common.delete') }}</v-btn
        >
        <v-btn
          round
          large
          class="ml-0 mr-3 white sw-accent text-none"
          @click="$router.push({ name: 'audiences' })"
          >{{ $t('common.cancel') }}</v-btn
        >
        <v-btn
          v-if="!audienceId"
          round
          large
          class="ml-0 mr-3 sw-accent-bg sw-on-accent text-none"
          @click="createAudience()"
          :loading="isLoading"
          >{{ $t('common.save') }}</v-btn
        >
        <v-btn
          v-if="audienceId"
          round
          large
          class="ml-0 mr-3 sw-accent-bg sw-on-accent text-none"
          :disabled="!isEditable"
          :loading="isLoading"
          @click="updateAudience()"
          >{{ $t('common.save') }}</v-btn
        >
      </v-flex>
    </v-layout>

    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
import AudienceFilters from '@/components/AudiencePage/AudienceFilters.vue';
import AudienceUsers from '@/components/AudiencePage/AudienceUsers.vue';

export default {
  data: () => ({
    isLoaded: false,
    isLoading: false,
    audience: {},
    audienceFiltersCopy: [],
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    audienceId() {
      return this.$route.params.audience_id;
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
    isEditable() {
      return this.audience.group_plugin_id ? false : true;
    },
    audienceFilters: {
      get() {
        return this.$store.getters.audienceFilters;
      },
      set(v) {
        this.$store.commit('SET_AUDIENCE_FILTERS', v);
      },
    },
    audienceAllFilter() {
      return {
        property: 'user_type',
        operator: '=',
        value: 'all',
      };
    },
    audienceFilterAll: {
      get() {
        return this.$store.getters.audienceFilterAll;
      },
      set(v) {
        this.$store.commit('SET_AUDIENCE_FILTER_ALL', v);
      },
    },
    platformFilters: {
      get() {
        return this.$store.getters.platformFilters;
      },
      set(v) {
        this.$store.commit('SET_PLATFORM_FILTERS', v);
      },
    },
    roleFilters: {
      get() {
        return this.$store.getters.roleFilters;
      },
      set(v) {
        this.$store.commit('SET_ROLE_FILTERS', v);
      },
    },
    attributesFilters: {
      get() {
        return this.$store.getters.attributesFilters;
      },
      set(v) {
        this.$store.commit('SET_ATTRIBUTES_FILTERS', v);
      },
    },
    ticketTemplateFilters: {
      get() {
        return this.$store.getters.ticketTemplateFilters;
      },
      set(v) {
        this.$store.commit('SET_TICKET_TEMPLATE_FILTERS', v);
      },
    },
  },
  components: {
    AudienceFilters,
    AudienceUsers,
  },
  watch: {
    audienceFilterAll(val) {
      if (!val) {
        this.audienceFilters = this.audienceFiltersCopy;

        return;
      }
      this.audienceFiltersCopy = this.audienceFilters;
      this.audienceFilters = [this.audienceAllFilter];
    },
  },
  mounted() {
    this.fetchAll();
  },
  methods: {
    resetStoreValues() {
      this.audienceFilters = [];
      this.audienceFilterAll = false;
      this.platformFilters = [];
      this.roleFilters = [];
      this.attributesFilters = [];
      this.ticketTemplateFilters = [];
    },
    async fetchAll() {
      try {
        this.resetStoreValues();

        this.isLoading = true;

        await Promise.all([
          this.getAudience(),
          this.getPlatformFilters(),
          this.getRoleFilters(),
          this.getAttributesFilters(),
          this.listGroupTicketTemplates(),
        ]);

        this.isLoaded = true;
        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoaded = true;
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async getAudience(audienceId) {
      if (!audienceId && !this.audienceId) {
        return;
      }

      const params = [
        this.groupId,
        audienceId || this.audienceId,
        {
          with_attributes: 1,
          lang: this.appContentLanguage,
        },
      ];

      const response = await this.$api.groupAudiences.get(...params);

      this.audience = response.data.data;

      const audienceFilters = response.data.data.filters;

      this.audienceFilters = this.transformAudienceFilters(audienceFilters);

      this.audienceFilterAll = this.audienceFilters.some(
        (f) =>
          f.property === this.audienceAllFilter.property &&
          f.operator === this.audienceAllFilter.operator &&
          f.value === this.audienceAllFilter.value,
      );
    },
    async createAudience() {
      const isValid = await this.$validator.validate();

      if (!isValid) {
        return;
      }

      try {
        const specs = [
          this.groupId,
          {
            name: this.audience.name,
            description: this.audience.description,
            filters: this.audienceFilters,
            lang: this.appContentLanguage,
          },
        ];

        this.isLoading = true;

        const response = await this.$api.groupAudiences.create(...specs);

        const audienceId = response.data.data.id;

        await this.getAudience(audienceId);

        this.isLoading = false;

        this.$store.dispatch('addNotification', {
          message: this.$t('audience_page.audience_create_message'),
        });

        this.$router
          .push({
            name: 'audiences_edit',
            params: {
              audience_id: audienceId,
            },
          })
          .catch(() => {});
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async updateAudience() {
      const isValid = await this.$validator.validate();

      if (!isValid) return;

      try {
        const specs = [
          this.groupId,
          this.audience.id,
          {
            name: this.audience.name,
            description: this.audience.description,
            filters: this.audienceFilters,
            lang: this.appContentLanguage,
          },
        ];

        this.isLoading = true;

        const response = await this.$api.groupAudiences.update(...specs);

        this.isLoading = false;

        if (response.status === 200) {
          this.$store.dispatch('addNotification', {
            message: this.$t('audience_page.audience_update_message'),
          });
        }
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async deleteAudience() {
      const isConfirmed = await this.$refs.confirmationDialog.open(
        this.$t('audienceDeleteConfirmationTextLine', {
          name: `${this.audience.name}`,
        }),
        this.$t('common.no'),
        this.$t('common.yes'),
      );

      if (!isConfirmed) {
        return;
      }

      try {
        const specs = [this.groupId, this.audience.id];

        this.isLoading = true;

        await this.$api.groupAudiences.delete(...specs);

        this.isLoading = false;

        this.$store.dispatch('addNotification', {
          message: this.$t('audience_page.audience_delete_message', {
            name: this.audience.name,
          }),
        });

        this.$router
          .push({
            name: 'audiences',
          })
          .catch(() => {});
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    getPlatformFilters() {
      this.platformFilters = [
        {
          name: this.$t('audience_page.device_platform_category_name_label'),
          options: [
            {
              name: this.$t('audience_page.option_android_name_label'),
              property: 'push_tokens.platform',
              operator: 'in',
              value: 'android',
            },
            {
              name: this.$t('audience_page.option_ios_name_label'),
              property: 'push_tokens.platform',
              operator: 'in',
              value: 'ios',
            },
            {
              name: this.$t('audience_page.option_none_name_label'),
              property: 'push_tokens.platform',
              operator: '!exists',
              value: '1',
            },
          ],
        },
      ];
    },
    async getRoleFilters() {
      const questions = [
        {
          name: this.$t('audience_page.is_subscribed_question_name_label'),
          options: [
            {
              name: this.$t('common.yes'),
              property: 'subscribed_groups_ids',
              operator: '=',
              value: this.groupId,
            },
          ],
        },
      ];

      if (process.env.VUE_APP_ADMINISTRATOR_ROLES === 'true') {
        const params = { per_page: 100 };

        const response = await this.$api.groupRoles.list(params);

        questions.push({
          name: this.$t(
            'audience_page.administrator_roles_category_name_label',
          ),
          options: response.data.data.map((el) => ({
            name: el.description,
            property: `group_${this.groupId}_roles`,
            operator: 'in',
            value: el.name,
          })),
        });
      }

      this.roleFilters = [
        {
          name: this.$t('audience_page.roles_group_name_label'),
          questions,
        },
      ];
    },
    transformDefaultAttributes(attributes) {
      return attributes
        .map((a) => {
          if (a.type === 'options') {
            return {
              name: a.name,
              slug: a.slug,
              options: [
                {
                  name: this.$t('audience_page.option_not_answered_name_label'),
                  attribute_id: a.id,
                  operator: '!exists',
                  value: '1',
                },
                ...a.options.map((o) => ({
                  name: o.name,
                  attribute_id: a.id,
                  operator: 'in',
                  value: o.id.toString(),
                })),
              ],
            };
          }
        })
        .filter((v) => v);
    },
    transformReservedAttributes(attributes) {
      return attributes
        .map((a) => {
          const completed = /reserved_[a-zA-Z0-9]+_completed/;

          if (a.type === 'boolean' && completed.test(a.slug)) {
            return {
              name: a.name,
              slug: a.slug,
              options: [
                {
                  name: this.$t('common.yes'),
                  attribute_id: a.id,
                  operator: 'in',
                  value: '1',
                },
                {
                  name: this.$t('common.no'),
                  attribute_id: a.id,
                  operator: '!in',
                  value: '1',
                },
              ],
            };
          }

          const progress = /reserved_[a-zA-Z0-9]+_progress/;

          if (a.type === 'float' && progress.test(a.slug)) {
            return {
              name: a.name,
              slug: a.slug,
              options: [
                {
                  name: this.$t('audience_page.option_no_progress_name_label'),
                  attribute_id: a.id,
                  operator: '!exists',
                  value: '1',
                },
                {
                  name: this.$t('audience_page.option_incomplete_name_label'),
                  attribute_id: a.id,
                  operator: '<',
                  value: '100',
                },
              ],
            };
          }
        })
        .filter((v) => v);
    },
    async getAttributesFiltersSilent(pluginPrefix, withReserved) {
      const pluginParams = [
        this.groupId,
        {
          prefix: pluginPrefix,
          lang: this.appContentLanguage,
        },
      ];

      const pluginsResponse = await this.$api.groupPlugins.list(
        ...pluginParams,
      );

      const plugins = pluginsResponse.data.data;

      const groups = [];

      for (let i = 0; i < plugins.length; i++) {
        const attributesParams = [
          this.groupId,
          {
            group_plugin_id: plugins[i].id,
            types: 'options,boolean,float',
            with_reserved: withReserved,
            lang: this.appContentLanguage,
            per_page: 500,
          },
        ];

        const attributesResponse = await this.$api.groupUserAttributes.list(
          ...attributesParams,
        );

        const reservedAttributes = attributesResponse.data.data.filter((a) =>
          a.slug.includes('reserved_'),
        );
        const defaultAttributes = attributesResponse.data.data.filter(
          (a) => !a.slug.includes('reserved_'),
        );
        const allAttributes = [
          ...this.transformReservedAttributes(reservedAttributes),
          ...this.transformDefaultAttributes(defaultAttributes),
        ];

        groups.push({
          name: plugins[i].name,
          questions: allAttributes,
        });
      }

      return groups;
    },
    async getAttributesFilters() {
      const [signups, questionwizards, guestattributes] = await Promise.all([
        this.getAttributesFiltersSilent('signup', 1),
        this.getAttributesFiltersSilent('questionwizard', 1),
        this.getAttributesFiltersSilent('guestattributes', 0),
      ]);

      this.attributesFilters = [
        ...signups,
        ...questionwizards,
        ...guestattributes,
      ].filter((el) => el.questions.length > 0);
    },
    async listGroupTicketTemplates() {
      const response = await this.$api.groupTicketTemplates.list(this.groupId, {
        lang: this.appContentLanguage,
      });

      this.ticketTemplateFilters = response.data.data.map((el) => ({
        name: el.name,
        questions: [
          {
            name: this.$t('audience_page.has_ticket_question'),
            options: [
              {
                name: this.$t('common.yes'),
                property: `ticket_${el.id}`,
                operator: 'exists',
                value: '1',
              },
              {
                name: this.$t('common.no'),
                property: `ticket_${el.id}`,
                operator: '!exists',
                value: '1',
              },
            ],
          },
          {
            name: this.$t('audience_page.is_checked_in_question'),
            options: [
              {
                name: this.$t('common.yes'),
                property: `ticket_${el.id}.checked_in_at`,
                operator: 'exists',
                value: '1',
              },
              {
                name: this.$t('common.no'),
                property: `ticket_${el.id}.checked_in_at`,
                operator: '!exists',
                value: '1',
              },
            ],
          },
        ],
      }));
    },
    transformAudienceFilters(filters) {
      return filters.map((el) => {
        if (el.attribute_id) {
          return {
            attribute_id: el.attribute_id,
            operator: el.operator,
            value: el.value,
          };
        }

        if (el.property) {
          return {
            property: el.property,
            operator: el.operator,
            value: el.value,
          };
        }
      });
    },
    audienceFiltersTransformed() {
      const transformedFilters = [];

      for (let i = 0; i < this.audienceFilters.length; i++) {
        const filter = this.audienceFilters[i];
        const filterValues = filter.value.split(',');

        let type = '';

        // All filters with groups
        let groups = [
          ...this.roleFilters,
          ...this.attributesFilters,
          ...this.ticketTemplateFilters,
        ];

        if (filter.property) {
          // Skip audience all filter
          if (filter.property.includes('user_type')) {
            continue;
          }

          type = 'property';
        }

        if (filter.attribute_id) {
          type = 'attribute_id';
        }

        if (!type) continue;

        let group = {};

        group = groups.find((g) =>
          g.questions.find((q) =>
            filterValues.every((v) =>
              q.options.find(
                (o) =>
                  o[type] === filter[type] &&
                  o.operator === filter.operator &&
                  o.value === v,
              ),
            ),
          ),
        );

        if (!group) {
          // Use question as group for filters without groups
          const question = this.platformFilters.find((q) =>
            filterValues.every((v) =>
              q.options.find(
                (o) =>
                  o[type] === filter[type] &&
                  o.operator === filter.operator &&
                  o.value === v,
              ),
            ),
          );

          if (question) {
            // Filters without groups
            group = {
              name: question.name,
              questions: [
                {
                  name: '',
                  options: question.options,
                },
              ],
            };
          } else {
            // Fallback group
            group = {
              name: this.$t('audience_page.filter_group_not_found'),
              questions: [],
            };
          }
        }

        let question = {};

        question = group.questions.find((q) =>
          filterValues.every((v) =>
            q.options.find(
              (o) =>
                o[type] === filter[type] &&
                o.operator === filter.operator &&
                o.value === v,
            ),
          ),
        );

        // Fallback question
        if (!question) {
          question = {
            name: this.$t('audience_page.filter_question_not_found'),
            options: [],
          };
        }

        let options = filterValues
          .map((v) =>
            question.options.find(
              (o) =>
                o[type] === filter[type] &&
                o.operator === filter.operator &&
                o.value === v,
            ),
          )
          .filter((o) => o);

        // Fallback options as filter values
        if (!options || !options.length) {
          options = filterValues.map((v) => ({ name: v }));
        }

        const newFilter = {
          group: group.name,
          question: question.name,
          options: options.map((o) => o.name),
          ...filter,
        };

        transformedFilters.push(newFilter);
      }

      return transformedFilters;
    },
    removeFilter(index) {
      if (index < 0) return;

      this.$delete(this.audienceFilters, index);
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  opacity: 0.4;
  transition: all 0.2s;
  pointer-events: none;
}
</style>
