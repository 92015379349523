<template>
  <div>
    <v-layout class="row wrap">
      <v-flex class="xs12 mb-3">
        <TranslatableTooltip
          :content="$t('audience_page.filters_title_tooltip')"
        >
          <div class="font-weight-bold">
            {{ $t("audience_page.filters_title") }}
          </div>
        </TranslatableTooltip>
      </v-flex>
      <v-flex class="xs12">
        <TranslatableTooltip
          :content="$t('audience_page.audience_filter_all_label_tooltip')"
        >
          <v-checkbox
            v-model="audienceFilterAll"
            :label="$t('audience_page.audience_filter_all_label')"
            class="my-0"
          >
          </v-checkbox>
        </TranslatableTooltip>
      </v-flex>
    </v-layout>
    <v-layout v-if="!audienceFilterAll" class="row wrap">
      <v-flex class="xs12">
        <v-text-field
          v-model="audienceFiltersSearchInput"
          :label="$t('audience_page.audience_filters_search_input_label')"
          append-icon="search"
        ></v-text-field>
      </v-flex>
      <v-flex class="xs12">
        <v-expansion-panel>
          <!-- Platforms -->
          <v-expansion-panel-content
            v-for="(question, questionIndex) in searchFilters(platformFilters)"
            :key="`platform-question-${questionIndex}`"
          >
            <div slot="header">
              <span>{{ question.name }}</span>
            </div>
            <v-card>
              <v-card-text class="pa-0">
                <v-list class="pa-0">
                  <v-list-tile
                    v-for="(option, optionIndex) in question.options"
                    :key="`platform-question-option-${optionIndex}`"
                    avatar
                    @click="clickOption(option)"
                  >
                    <v-list-tile-action>
                      <v-icon v-if="!isOptionActive(option)" color="grey"
                        >check_box_outline_blank</v-icon
                      >
                      <v-icon v-if="isOptionActive(option)" class="sw-accent"
                        >check_box</v-icon
                      >
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <div v-text="option.name"></div>
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
          <!-- Roles -->
          <v-expansion-panel-content
            v-for="(group, groupIndex) in searchGroupedFilters(roleFilters)"
            :key="`role-group-${groupIndex}`"
          >
            <div slot="header">
              {{ group.name }}
            </div>
            <v-card class="grey lighten-4">
              <v-card-text class="pa-2">
                <v-expansion-panel>
                  <v-expansion-panel-content
                    v-for="(question, questionIndex) in group.questions"
                    :key="`role-group-question-${questionIndex}`"
                  >
                    <div slot="header">
                      <span>{{ question.name }}</span>
                    </div>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-list class="pa-0">
                          <v-list-tile
                            v-for="(option, optionIndex) in question.options"
                            :key="`role-group-question-option-${optionIndex}`"
                            avatar
                            @click="clickOption(option)"
                          >
                            <v-list-tile-action>
                              <v-icon
                                v-if="!isOptionActive(option)"
                                color="grey"
                                >check_box_outline_blank</v-icon
                              >
                              <v-icon
                                v-if="isOptionActive(option)"
                                class="sw-accent"
                                >check_box</v-icon
                              >
                            </v-list-tile-action>
                            <v-list-tile-content>
                              <v-list-tile-title>
                                <div v-text="option.name"></div>
                              </v-list-tile-title>
                            </v-list-tile-content>
                          </v-list-tile>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
          <!-- Group plugins -->
          <v-expansion-panel-content
            v-for="(group, groupIndex) in searchGroupedFilters(
              attributesFilters,
            )"
            :key="`attribute-group-${groupIndex}`"
          >
            <div slot="header">
              {{ group.name }}
            </div>
            <v-card class="grey lighten-4">
              <v-card-text class="pa-2">
                <v-expansion-panel>
                  <v-expansion-panel-content
                    v-for="(question, questionIndex) in group.questions"
                    :key="'attribute-group-question-' + questionIndex"
                  >
                    <div slot="header">
                      {{ question.name }}
                    </div>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-list class="pa-0">
                          <template
                            v-for="(option, optionIndex) in question.options"
                          >
                            <div
                              :key="`user-attribute-option-divider-${optionIndex}`"
                              v-if="
                                !question.slug.includes('reserved_') &&
                                optionIndex === 1
                              "
                              class="px-2"
                            >
                              <v-divider></v-divider>
                            </div>
                            <v-list-tile
                              :key="`attribute-group-question-option-${optionIndex}`"
                              avatar
                              @click="clickOption(option)"
                            >
                              <v-list-tile-action>
                                <v-icon
                                  v-if="!isOptionActive(option)"
                                  color="grey"
                                  >check_box_outline_blank</v-icon
                                >
                                <v-icon
                                  v-if="isOptionActive(option)"
                                  class="sw-accent"
                                  >check_box</v-icon
                                >
                              </v-list-tile-action>
                              <v-list-tile-content>
                                <v-list-tile-title>
                                  <div v-text="option.name"></div>
                                </v-list-tile-title>
                              </v-list-tile-content>
                            </v-list-tile>
                          </template>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
          <!-- Group ticket templates -->
          <v-expansion-panel-content
            v-for="(group, groupIndex) in searchGroupedFilters(
              ticketTemplateFilters,
            )"
            :key="'ticket-template-group-' + groupIndex"
          >
            <div slot="header">
              {{ group.name }}
            </div>
            <v-card class="grey lighten-4">
              <v-card-text class="pa-2">
                <v-expansion-panel>
                  <v-expansion-panel-content
                    v-for="(question, questionIndex) in group.questions"
                    :key="`ticket-template-group-question-${questionIndex}`"
                  >
                    <div slot="header">
                      <span>{{ question.name }}</span>
                    </div>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-list class="pa-0">
                          <v-list-tile
                            v-for="(option, optionIndex) in question.options"
                            :key="`ticket-template-group-question-option-${optionIndex}`"
                            avatar
                            @click="clickOption(option)"
                          >
                            <v-list-tile-action>
                              <v-icon
                                v-if="!isOptionActive(option)"
                                color="grey"
                                >check_box_outline_blank</v-icon
                              >
                              <v-icon
                                v-if="isOptionActive(option)"
                                class="sw-accent"
                                >check_box</v-icon
                              >
                            </v-list-tile-action>
                            <v-list-tile-content>
                              <v-list-tile-title>
                                <div v-text="option.name"></div>
                              </v-list-tile-title>
                            </v-list-tile-content>
                          </v-list-tile>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  data: () => ({
    audienceFiltersSearchInput: "",
    groupTicketTemplates: [],
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    audienceFilters: {
      get() {
        return this.$store.getters.audienceFilters;
      },
      set(v) {
        this.$store.commit("SET_AUDIENCE_FILTERS", v);
      },
    },
    audienceFilterAll: {
      get() {
        return this.$store.getters.audienceFilterAll;
      },
      set(v) {
        this.$store.commit("SET_AUDIENCE_FILTER_ALL", v);
      },
    },
    platformFilters() {
      return this.$store.getters.platformFilters;
    },
    roleFilters() {
      return this.$store.getters.roleFilters;
    },
    attributesFilters() {
      return this.$store.getters.attributesFilters;
    },
    ticketTemplateFilters() {
      return this.$store.getters.ticketTemplateFilters;
    },
  },
  methods: {
    isOptionActive(option) {
      return this.audienceFilters.some(
        (el) =>
          ((el.property && el.property === option.property) ||
            (el.attribute_id && el.attribute_id === option.attribute_id)) &&
          el.operator === option.operator &&
          el.value.includes(option.value),
      );
    },
    clickOption(option) {
      let newFilter = {};
      let index = 0;

      if (option.attribute_id) {
        newFilter = {
          attribute_id: option.attribute_id,
          operator: option.operator,
          value: option.value,
        };

        index = this.audienceFilters.findIndex(
          (el) => el.attribute_id === newFilter.attribute_id,
        );
      }

      if (option.property) {
        newFilter = {
          property: option.property,
          operator: option.operator,
          value: option.value,
        };

        index = this.audienceFilters.findIndex(
          (el) => el.property === newFilter.property,
        );
      }

      if (index === -1) {
        this.audienceFilters.push(newFilter);
        return;
      }

      if (newFilter.operator !== this.audienceFilters[index].operator) {
        this.audienceFilters[index].operator = newFilter.operator;
        this.audienceFilters[index].value = newFilter.value;
        return;
      }

      const isSelected = this.audienceFilters[index].value.includes(
        newFilter.value,
      );

      const value = this.audienceFilters[index].value.split(",");

      if (!isSelected) {
        this.audienceFilters[index].value = [...value, newFilter.value].join(
          ",",
        );
        return;
      }

      if (value.length === 1) {
        this.$delete(this.audienceFilters, index);
        return;
      }

      this.audienceFilters[index].value = value
        .filter((el) => el !== newFilter.value)
        .join(",");
    },
    searchFilters(filters) {
      if (
        !this.audienceFiltersSearchInput ||
        !this.audienceFiltersSearchInput.length
      ) {
        return filters;
      }

      const filterdFilters = filters.filter((q) =>
        q.name
          .toLowerCase()
          .includes(this.audienceFiltersSearchInput.toLowerCase()),
      );

      return filterdFilters.filter((f) => f.options.length);
    },
    searchGroupedFilters(filters) {
      if (
        !this.audienceFiltersSearchInput ||
        !this.audienceFiltersSearchInput.length
      ) {
        return filters;
      }

      const filterdFilters = [];

      filters.forEach((f) => {
        const questions = f.questions.filter((q) =>
          q.name
            .toLowerCase()
            .includes(this.audienceFiltersSearchInput.toLowerCase()),
        );

        if (!questions && !questions.length) return;

        filterdFilters.push({
          name: f.name,
          questions,
        });
      });

      return filterdFilters.filter((f) => f.questions.length);
    },
  },
};
</script>

<style lang="scss" scoped></style>
